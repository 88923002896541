<template>
  <div>
    <div class="animation">
      <h1>Na, wie gehts?</h1>
    </div>
    <div class="content" v-if="!restart" :class="skipped ? 'no-anmation' : 'show-animation'">
      <div class="content_boo">
        <p class="question">{{ item.question }}</p>
        <p class="answer" :class="skipped ? 'no-delay' : ''">{{ item.answer }}</p>
      </div>
      <img :class="skipped ? 'no-delay' : ''" src="https://media0.giphy.com/media/qgri3D9sTwCUGMcT8L/giphy.gif?cid=6c09b952tck56kwvbe3qmtx5vksfzht9za3kwiv9z7i43q5g&ep=v1_gifs_search&rid=giphy.gif&ct=g" alt="">
      <div :class="skipped ? 'no-delay' : ''" class="button" @click="newItem()">Another one</div>
      <h4 class="viewed">Read {{ this.items.filter(item => item.shown).length }} of {{ items.length }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      items: [
        {"question": "Warum hat der Teddybär keinen Kuchen gegessen?", "answer": "Weil er schon gestopft ist!", "shown": false},
        {"question": "Was sagt ein Null zu einer Acht?", "answer": "Schicker Gürtel!", "shown": false},
        {"question": "Warum hat der Besen den Preis gewonnen?", "answer": "Weil er so umwerfend war!", "shown": false},
        {"question": "Warum hat die Orange den Apfel geheiratet?", "answer": "Weil sie ihn toll fand!", "shown": false},
        {"question": "Wie nennt man einen Bumerang, der nicht zurückkommt?", "answer": "Stock!", "shown": false},
        {"question": "Wie nennt man Käse, der dir winkt?", "answer": "Gouda-bye!", "shown": false},
        {"question": "Warum dürfen Geister lügen?", "answer": "Weil sie durchschaut werden können!", "shown": false},
        {"question": "Warum hat der Staubsauger keinen Abschluss?", "answer": "Weil er nur saugt und nicht bläst!", "shown": false},
        {"question": "Warum hat die Schere gewonnen?", "answer": "Weil der Stein schlecht gewickelt war!", "shown": false},
        {"question": "Warum hat die Tomate Tomate gesagt?", "answer": "Sie war rot!", "shown": false},
        {"question": "Warum hat der Hut kein Geld?", "answer": "Weil er immer auf dem Kopf sitzt!", "shown": false},
        {"question": "Was macht eine Null nach der anderen?", "answer": "Sie addieren sich!", "shown": false},
        {"question": "Was macht ein Schneemann im Sommer?", "answer": "Er schmilzt dahin!", "shown": false},
        {"question": "Warum hat der Kühlschrank Licht?", "answer": "Damit er sieht, was er kühlt!", "shown": false},
        {"question": "Was sagt ein Schwarzes Loch zum anderen?", "answer": "Hallo, noch jemand da drin?", "shown": false},
        {"question": "Warum hat der Besen den Job gewechselt?", "answer": "Er fand ihn zu langweilig!", "shown": false},
        {"question": "Warum war der Computer krank?", "answer": "Er hatte einen Virus!", "shown": false},
        {"question": "Wie nennt man einen Fisch ohne Augen?", "answer": "Fisch!", "shown": false},
        {"question": "Warum tragen Geister immer einen Mantel?", "answer": "Weil es zieht!", "shown": false},
        {"question": "Warum hat der Tomatenketchup rote Haare?", "answer": "Weil er aus pürierten Tomaten besteht!", "shown": false},
        {"question": "Warum hat der Staubsauger geheiratet?", "answer": "Er wollte eine starke Verbindung!", "shown": false},
        {"question": "Warum hat der Apfel geweint?", "answer": "Weil er geschält wurde!", "shown": false},
        {"question": "Was sagt ein Lichtschalter, bevor er ausgeschaltet wird?", "answer": "Auf Wiedersehen!", "shown": false},
        {"question": "Warum hat die Schere den Rock nicht geschnitten?", "answer": "Weil sie auf Hosen steht!", "shown": false},
        {"question": "Was sagt eine Null zu einer Acht?", "answer": "Schicker Gürtel!", "shown": false},
        {"question": "Warum war die Mathematikbuch traurig?", "answer": "Es hatte zu viele Probleme!", "shown": false},
        {"question": "Warum hat der Kühlschrank immer recht?", "answer": "Weil er immer kühl bleibt!", "shown": false},
        {"question": "Wie nennt man einen Katzen Haufen?", "answer": "Ein Katzenklo!", "shown": false},
        {"question": "Warum hat der Bleistift eine Radiergummi-Party geschmissen?", "answer": "Um seine Fehler zu korrigieren!", "shown": false}
      ],
      item: {},
      restart: false,
      skipped: false,
    }
  },
  methods: {
    newItem() {
      this.skipped = true;
      let itemList = this.items.filter(item => !item.shown);
      if (itemList.length <= 0) {
        console.error("NO ITEMS FOUND");
        return;
      }
      let id = Math.floor(Math.random() * itemList.length);
      itemList[id].shown = true;
      this.item = itemList[id];
      this.restart = true;
      setTimeout(() => {
        this.restart = false;
      }, 10)
      
    }
  }, 
  created() {
    let itemList = this.items.filter(item => !item.shown);
    if (itemList.length <= 0) {
      console.error("NO ITEMS FOUND");
      return;
    }
    let id = Math.floor(Math.random() * itemList.length);
    itemList[id].shown = true;
    this.item = itemList[id];
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
html {
  overflow: hidden;
}
body {
  background-color: #000;
  overflow: hidden;
  box-sizing: border-box;
}

* {
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.animation {
  animation: example;
  animation-duration: 4s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  opacity: 0;
}

img {
  width: 250px;
  animation: example3;
  animation-delay: 3s;
  animation-duration: 8s;
}

h1 {
  font-size: 50px;
}

h4 {
  position: absolute;
  bottom: 10px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 200px);
}

.show-animation {
  animation: example2;
  animation-duration: 4s;
}

.no-delay {
  animation-delay: 0ms !important;
}

p {
  text-align: center;
  font-size: 28px;
}

.answer {
  animation: example3;
  animation-delay: 3s;
  animation-duration: 4s;
  margin-top: 50px;
}

.question {
  animation: example4;
  animation-duration: 1s;
}

.button {
  animation: example3;
  animation-delay: 3s;
  animation-duration: 6s;
  position: absolute;
  bottom: 100px;
  font-size: 24px;
  background-color: rgb(17, 206, 127);
  border-radius: 5px;
  padding: 20px 60px;
}

@keyframes example {
  0%   {transform: translateY(-1000px); opacity: 0;}
  25%  {transform: translateY(0); opacity: 1;}
  75%  {transform: translateY(0); opacity: 1;}
  100% {transform: translateY(-1000px); opacity: 0;}
}

@keyframes example2 {
  0%   {opacity: 0;}
  75%  {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes example3 {
  0%   {opacity: 0; transform: translateY(1000px);}
  75%  {opacity: 0; transform: translateY(300px);}
  85%  {opacity: 0.5; transform: translateY(0);}
  100% {opacity: 1;}
}

@keyframes example4 {
  0%   {opacity: 0;}
  100% {opacity: 1;}
}
</style>
